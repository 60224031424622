import "./styles.less";

import { Badge } from "antd";
import { BellOutlined } from "shared-components/Components/CustomIcons/BellOutlinedIcon/web";
import { UserOutlined } from "shared-components/Components/CustomIcons/UserOutlinedIcon/web";
import React, { useState } from "react";
import { Button } from "shared-components/Components/Buttons/web";
import { Logout } from "../../../Components/User/LogOut/web";
import { openAuthModal } from "../../../Components/User/AuthModal/openAuthModal";
import { useUserBox } from "./hook";
import getConfig from "next/config";
const { ovPath } = getConfig().publicRuntimeConfig;

import dynamic from "next/dynamic"
import { ButtonGarantíasAlquiler } from "shared-components/Components/ButtonGarantíasAlquiler/web";
import { LoginIcon } from "shared-components/Components/CustomIcons/LoginIcon/web";
const NotificationList = dynamic(
	(): any => import("../../../Components/User/NotificationList/web").then(mod => mod.NotificationList),
	{ ssr: false }
)

export const UserBox = ({ isMobile = false }) => {
	const { main_domain, data, country_code, user, isLoggedIn, dataPP } = useUserBox();
	let urlPublicateProperty = "https://" + main_domain + "/sitio/index.php?mid=propiedades&func=add";

	if (country_code === "ce3") urlPublicateProperty = "https://www.infocasas.com.uy/publicar-alquiler-temporario"
	if (country_code === "CO") urlPublicateProperty = `${ovPath}publicar`;

	const handleClickLogin = (e) => {
		e.preventDefault();

		openAuthModal({ defaultModalType: "LogIn", isFromCta: true })
	}

	const IrisButton = (
		<a className={isMobile ? 'header_btn_mobile' : 'header_btn'} href={"https://" + main_domain + "/proyectos/iris"}>
			{"Iris"}
		</a>
	);

	const PublicatePropertyButton = (
		<a className={`btn-publish ${isMobile ? 'header_btn_mobile' : ''} ${country_code}`}
			href={urlPublicateProperty}
			target={country_code == 'ce3' || country_code == 'CO' ? '_blank' : '_self'}>
			{"Publica tu propiedad"}
		</a>
	);

	const LoginUserButton =
		isMobile ?
			<span style={{ order: -1 }} className="header_btn_mobile btn-secondary" onClick={handleClickLogin}>
				<LoginIcon width={16} /> {"Ingresar"}
			</span>
			:
			<Button type="noStyles" onClick={handleClickLogin} className="btn-secondary">
				<LoginIcon width={16} /> {"Ingresar"}
			</Button>
		;

	const PublicatePropertyDropdown = <MenuDropDown
		isMobile={isMobile}
		btnClass="btn-primary"
		text={"Publica tu propiedad"}
		content={dataPP.map((e, i) => (
			<a key={"key_favorite" + i}
				className={isMobile ? 'header_btn_mobile' : ''}
				target={e.target ?? "_self"}
				href={e.url.substring(0,4)==="http"?e.url:"https://" + main_domain + e.url}>
				{e.title}
			</a>
		))}
	/>

	return (
		<div className={isMobile ? 'userbox-mobile' : 'userbox'}>
			{/* Garantías de Alquiler */}
			{(country_code === "UY" && isLoggedIn && !user?.data?.me?.individual) &&
				<ButtonGarantíasAlquiler isMobile={isMobile} />
			}

			{/* Ir a Iris */}
			{user?.data?.me?.isIris && (country_code != "BR" && country_code != "ce3") && IrisButton}

			{/* Contacto */}
			{country_code === "ce3" &&
				<a className={isMobile ? 'header_btn_mobile' : 'header_btn'} href="/contacto">
					{"Contacto"}
				</a>
			}

			{/* Login  */}
			{isLoggedIn ? <>
				<MenuDropDown
					isMobile={isMobile}
					btnClass={`btn-secondary ${!isMobile ? 'btn-user' : ''}`}
					text={
						<>
							<span className="icon-user"><UserOutlined width={16} /></span>
							<span className="username">{user?.data?.me.name}</span>
						</>
					}
					content={<>
						{data.map((e, i) => (
							<a className={isMobile ? 'header_btn_mobile' : ''} key={"key_user_data " + i} target={e.target ?? "_self"} href={e.url.substring(0,4)==="http"?e.url:"https://" + main_domain + e.url}>{e.title}</a>
						))}
						<Logout className={isMobile ? 'header_btn_mobile' : ''} />
					</>}
				/>
			</> :
				LoginUserButton
			}

			{/* Publicar Propiedad */}
			{country_code === "BR"
				? null
				: isLoggedIn
					? user?.data?.me?.individual && country_code !== "ce3" && country_code !== "CO"
						? PublicatePropertyDropdown
						: PublicatePropertyButton
					: country_code === "ce3" || country_code === "CO"
						? PublicatePropertyButton
						: PublicatePropertyDropdown
			}

			{/* Notificaciones  */}
			{isLoggedIn && country_code != "BR" && <>
				<MenuDropDown
					isMobile={isMobile}
					contentClass="noHover dropdown-to-left"
					btnClass={`${isMobile ? "btn-secondary" : "btn-notification"}`}
					text={<>
						<Badge
							count={user?.data?.me.unread_notifications}
							overflowCount={9}
							size={"small"}
						>
							<BellOutlined width={20} />
						</Badge>
						{isMobile ? <>{'Notificaciones'}</> : <></>}
					</>}
					content={<>
						<NotificationList />
					</>}
				/>
			</>}
		</div>
	);
};

const MenuDropDown = (
	{ text, content, isMobile = false, contentClass = '', btnClass = '' }
		: { text: any, content: any, isMobile?: boolean, contentClass?: string, btnClass?: string }
) => {
	const [open, setOpen] = useState(false)

	return (!isMobile ? <>
		<div className="userbox-dropdown">
			<span className={btnClass}>
				{text}
			</span>
			<div className={`userbox-dropdown-content ${contentClass}`}>
				{content}
			</div>
		</div>
	</> : <>
		<span className={`${btnClass} header_btn_mobile`} onClick={() => { setOpen(!open) }}>
			{text}
		</span>
		{open &&
			<div className={`userbox-dropdown-content_mobile ${contentClass}`}>
				{content}
			</div>
		}
	</>)
}