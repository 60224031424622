import { FragmentDefiner, useReadFragment } from "../../../GlobalHooks/useReadFragment";
import { REGISTER_MUTATION, USER_EXISTS_QUERY } from "../../User/User.querys";
import { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
	useFormulario,
	valueFormDataType,
} from "../../../../shared-components/GlobalHooks/web/useFormulario";
import { PropComponentMode } from "../../Property/PropertyInterfaces";
import { gql } from "@apollo/client/core";
import { useUser } from "../../User/User.hook";
import { isTransactional } from "../../../Utils/Functions";
import { useCreateSearchAlert } from "shared-components/Components/SearchAlert/CreateSearchAlert/hook";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";

interface useContactParams {
	id: number;
	onComplete: (formType: number) => void;
	type: string;
	mode: PropComponentMode;
	source?: number;
	utm?: any;
	env?: "development" | "production";
}

enum VisitType {
	INPERSON = "INPERSON",
	VIRTUAL = "VIRTUAL",
}

enum PropEntityType {
	PROPERTY = "PROPERTY",
	PROJECT = "PROJECT",
}

interface FormData {
	email: string;
	phone: number | string;
	name: string;
	message?: string;
	financing_information?: boolean | undefined;
	date?: string;
	visit_type?: VisitType;
	isFromPaidTraffic?: string;
	typeForm?: number;
	busca_por_ti?: boolean;
	is_terms_and_conditions_accepted?: boolean;
	isProject?: boolean,
	projectId?: string
}

interface ContactInput {
	property_id: number;
	source: number;
	type: PropEntityType;
	financing_information: boolean;
	message?: string;
	visit_type?: string;
	date?: string;
	utm_args?: UtmArgs;
	isFromPaidTraffic?: string;
	is_terms_and_conditions_accepted?: boolean
}

export interface UtmArgs {
	utm_content?: string | string[];
	utm_source?: string | string[];
	utm_medium?: string | string[];
	utm_campaign?: string | string[];
}

export const FRAGMENT_CONTACT_FORM = new FragmentDefiner(
	"Property",
	`
  project {
    id
	id_form
  }
  highlight
`
);

const SEND_LEAD_MUTATION = gql`
	mutation sendLead($input: LeadInput!) {
		sendLead(input: $input) {
			id
		}
	}
`;

const SCHEDULE_VISIT_MUTATION = gql`
	mutation scheduleVisit($input: ScheduleInput!) {
		scheduleVisit(input: $input) {
			id
		}
	}
`;

const UPDATE_USER_MUTATION = gql`
	mutation updateUser($name: String, $phone: String) {
		updateUser(name: $name, phone: $phone) {
			id
		}
	}
`;

export const useContact = ({
	id,
	onComplete,
	type,
	mode = "auto",
	source = 0,
	utm = null,
	env,
}: useContactParams) => {
	const [formData, setFormData] = useState<FormData | null>(null);
	const [formComplete, setFormComplete] = useState(false);
	const { data } = useReadFragment(FRAGMENT_CONTACT_FORM, id);
	const { user, isLoggedIn } = useUser();
	const [leadID, setLeadID] = useState<string>("");
	const [IDform, setIDform] = useState(null);
	const { sendFormulario } = useFormulario({ IDform, env });
	const { searchAlert } = useCreateSearchAlert();
	const [isSendedConsultation, setIsSendedConsultation] = useState({
		lowerPrice: false,
		wantedCall: false,
		consultationForm: false,
		scheduleVisit: false,
		sendConsultation: false,
		coordinateVisit: false,
		askMoreInfo: false
	});

	const { site_name } = useContext(ConfigStateContext)

	const makeExtra = values => {
		let res = values["financing_information"]
			? "Requiere información de financiación" + "."
			: "No requiere información de financiación" + ".";
		if (values["message"]) res += " " + "Mensaje" + ": " + values["message"] + ".";
		if (values["date"]) res += " " + "Fecha de agenda" + ": " + values["date"] + ".";
		return res;
	};

	const [contactMutation, { loading: contactLoading, data: contactData }] = useMutation(
		type == "lead" ? SEND_LEAD_MUTATION : SCHEDULE_VISIT_MUTATION,
		{
			onCompleted: res => {
				setLeadID(res?.sendLead?.id ?? "");
				setFormComplete(true);
			},
			onError: error => {
				console.error(error);
			}
		}
	);
	const [
		registerMutation,
		{ loading: registerLoading, data: dataRegisterMutation },
	] = useMutation(REGISTER_MUTATION);
	const [updateUserMutation, { loading: updateLoading }] = useMutation(UPDATE_USER_MUTATION);

	const [
		userExistsQuery,
		{ loading: userExistsLoading, data: dataUserExist, error },
	] = useLazyQuery(USER_EXISTS_QUERY, {
		fetchPolicy: "network-only",
		onError: error => {
			if (error.message.includes("no existe")) {
				registerMutation({
					variables: {
						email: formData.email,
						name: formData.name,
					},
				})
					.then(({ data }) => {
						updateUser({ name: formData.name, phone: formData.phone }).then(() => {
							doContactMutation().then(() => user.refetch());
						});
					})
					.catch(error => {
						console.error(error);
					});
			}
		},
	});

	useEffect(() => {
		if (formData) {
			if (isLoggedIn) {
				updateUser({ name: formData.name, phone: formData.phone }).then(data => {
					doContactMutation().then(() => user.refetch());
				});
			} else {
				userExistsQuery({ variables: { email: formData.email } });
			}
		}
	}, [formData]);

	useEffect(() => {
		if (IDform) {
			sendTransactionalContact()
				.then(_ => {
					if (_.err) {
						throw new Error(_.err);
					} else {
						if (onComplete) onComplete(0);
					}
					setIDform(null);
				})
				.catch(error => {
					console.error(error);
					setIDform(null);
				});
		}
	}, [IDform]);

	const updateUser = ({ name, phone }) => {
		return updateUserMutation({ variables: { name, phone } });
	};

	const doContactMutation = (formType: number = 0) => {
		let property_id: number;
		let propertyType: PropEntityType;

		if ((data?.project?.length && (mode == "auto" || mode == "project")) || (formData && formData?.isProject == true)) {
			property_id = formData && formData?.projectId ? formData?.projectId : (data && data?.project ? data?.project[0]?.id : id);
			propertyType = PropEntityType.PROJECT;
		} else {
			property_id = id;
			propertyType = mode == "project" ? PropEntityType.PROJECT : PropEntityType.PROPERTY;
		}

		let contactInput: ContactInput = {
			property_id,
			source: formType ? 14 : source,
			type: propertyType,
			financing_information: formType ? false : formData?.financing_information,
			is_terms_and_conditions_accepted: formData?.is_terms_and_conditions_accepted
		};

		if (type == "lead") {
			contactInput.message = formType ? formType.toString() : formData.message;
		} else {
			contactInput.date = formData?.date;
			contactInput.visit_type = formData?.visit_type;
		}

		if (formData?.isFromPaidTraffic) {
			contactInput.isFromPaidTraffic = formData?.isFromPaidTraffic;
		}

		if (utm && (utm.utm_campaign || utm.utm_content || utm.utm_medium || utm.utm_source)) {
			contactInput.utm_args = {};

			if (utm.utm_campaign) {
				contactInput.utm_args.utm_campaign = Array.isArray(utm.utm_campaign) ? utm.utm_campaign[0] : utm.utm_campaign.toString();
			}
			if (utm.utm_content) {
				contactInput.utm_args.utm_content = Array.isArray(utm.utm_content) ? utm.utm_content[0] : utm.utm_content.toString();
			}
			if (utm.utm_medium) {
				contactInput.utm_args.utm_medium = Array.isArray(utm.utm_medium) ? utm.utm_medium[0] : utm.utm_medium.toString();
			}
			if (utm.utm_source) {
				contactInput.utm_args.utm_source = Array.isArray(utm.utm_source) ? utm.utm_source[0] : utm.utm_source.toString();
			}
		}


		if (formData && formData.busca_por_ti) {
			const idealFilters = JSON.parse(localStorage.getItem("propertyIdealFilters"))

			if (idealFilters) {
				searchAlert.customForm(1, `Quiero que ${site_name} busque por mí`, idealFilters)
			}
		}

		if (isTransactional(data?.highlight)) {
			setIDform(data?.project[0]?.id_form);
			return Promise.resolve();
		} else {
			return contactMutation({
				variables: {
					input: contactInput,
				},
			})
				.then(response => {
					if (onComplete) onComplete(formType);

					if (formType === 1) setIsSendedConsultation({ ...isSendedConsultation, lowerPrice: true });
					else if (formType === 2) setIsSendedConsultation({ ...isSendedConsultation, wantedCall: true });
					else if (formType === 99) setIsSendedConsultation({ ...isSendedConsultation, wantedCall: true });
					else if (formType === 3) setIsSendedConsultation({ ...isSendedConsultation, scheduleVisit: true });
					else if (formType === 4) setIsSendedConsultation({ ...isSendedConsultation, askMoreInfo: true });
					else if (formData && formData.typeForm === 4) setIsSendedConsultation({ ...isSendedConsultation, sendConsultation: true });
					else if (formData && formData.typeForm === 5) setIsSendedConsultation({ ...isSendedConsultation, coordinateVisit: true });

					return response;
				})
				.catch(error => {
					console.error(error);
				});
		}
	};

	const sendTransactionalContact = () => {
		const sendValues: valueFormDataType = {
			nombre: formData?.name,
			email: formData?.email,
			fecha: formData?.date,
			tel: formData?.phone,
			extra: makeExtra(formData),
		};
		return sendFormulario(sendValues, utm);
	};
	const sendContact = data => {
		const newFormData: FormData = {
			email: data.email,
			name: data.name,
			phone: data.phone,
			message: data?.message || '',
			financing_information: !!data.financing_information,
			date: data?.date
				? `${data.date.getFullYear()}-${data.date.getMonth() + 1}-${data.date.getDate()}`
				: null,
			visit_type: data?.visit_type == "INPERSON" ? VisitType.INPERSON : VisitType.VIRTUAL,
			isFromPaidTraffic: data?.isFromPaidTraffic,
			typeForm: data.isSendingConsultation ? 4 : data.isCoordinatingVisit ? 5 : 0,
			busca_por_ti: data.busca_por_ti,
			is_terms_and_conditions_accepted: data.fincatyc,
			isProject: data.isProject ?? false,
			projectId: data.projectId
		};

		setFormData(newFormData);
	};

	const resetForm = () => {
		setFormComplete(false);
		setFormData(null);
	};

	const loading = contactLoading || registerLoading || updateLoading || userExistsLoading;

	return {
		loading,
		error,
		formCompleted: formComplete,
		resetForm: resetForm,
		sendContact,
		disabledEmail: isLoggedIn,
		contactMutation,
		dataRegisterMutation,
		dataUserExist,
		updateUser,
		doContactMutation,
		leadID,
		isSendedConsultation
	};
};

